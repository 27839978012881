* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: #222
}

*:focus {
  outline: none;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

p {
  font: normal 4vw/2em 'Roboto Mono', monospace;
}

h1, h2, h3 {
  font: bold 10vw/2em 'Roboto Mono', monospace;
}

h2 {
  font-size: 7vw;
}

h3 {
  font-size: 5vw;
}

.loading-spinner {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-spinner::after {
  content: '...';
  width: 50vw;
  height: 50vh;
  line-height: 50vh;
  font-size: 10vw;
  text-align: center;
  position: absolute;
  left: 25vw;
  top: 25vh;
}

.top-brackets {
  position: absolute;
  top: 5vw;
  width: 100%;
}

.top-brackets:before {
  content: '';
  width: 2vw;
  height: 2vw;
  position: absolute;
  border-top: 1px solid #222;
  border-left: 1px solid #222;
  left: 1vw;
  top: 1vw;
}

.top-brackets:after {
  content: '';
  width: 2vw;
  height: 2vw;
  position: absolute;
  right: 1vw;
  top: 1vw;
  border-top: 1px solid #222;
  border-right: 1px solid #222;
}

.bottom-brackets {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottom-brackets:before {
  content: '';
  width: 2vw;
  height: 2vw;
  position: absolute;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
  left: 1vw;
  bottom: 1vw;
}

.bottom-brackets:after {
  content: '';
  width: 2vw;
  height: 2vw;
  position: absolute;
  right: 1vw;
  bottom: 1vw;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;
}

/*=============================================================================
  Nav
=============================================================================*/

nav {
  height: 12vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 10;
}

.menu {
  height: 100vh;
  width: 60vw;
  background-color: white;
  border-right: 1vw solid #222;
  position: absolute;
  left: 0;
  animation: slideout .2s linear;
  background-color: white;
  z-index: 100
}

.closed {
  animation: slideIn .2s linear;
  left: -60vw;
}

.menu ul {
  margin-top: 14vh;
}

.menu li {
  list-style-type: none;
  padding: 5vw
}

.menu a {
  color: #222;
  text-decoration: none;
  font: normal 5vw/5vw 'Roboto Mono', monospace;
}

.menu .selected {
  color: white;
  background-color: #222;
}

.menu .selected a {
  color: white;
}

@keyframes slideout {
  0% {
    left: -60vw;
  }
  100% {
    left: 0;
  }
}

@keyframes slideIn {
  0% {
    left: 0;
  }
  100% {
    left: -60;
  }
}

.nav-icon {
  width: 8vh;
  position: absolute;
  left: 20px;
  top: 20px;
  height: 8vh;
  z-index: 1000;
}

.nav-icon svg {
  position: absolute;
  height: 20%;
  width: 100%;
}

.nav-icon svg:nth-of-type(2) {
  top: 40%
}

.nav-icon svg:nth-of-type(3) {
  bottom: 0;
}

.open svg {
  transform: rotate(45deg);
  top: 35%;
}

.open svg:nth-of-type(2) {
  transform: rotate(-45deg);
  top: 35%;
}

.opening svg {
  animation: openingLeft .2s linear;
}

.opening svg:nth-of-type(1) {
  animation: opening .2s linear;
}

.closing svg {
  animation: closingLeft .2s linear
}

.closing svg:nth-of-type(1) {
  animation: closing .2s linear;
}

@keyframes opening {
  0% {
    transform: rotate(0deg);
    top: 0;
  }
  100% {
    transform: rotate(45deg);
    top: 35%;
  }
}

@keyframes openingLeft {
  0% {
    transform: rotate(0);
    bottom: 0;
  }
  100% {
    transform: rotate(-45deg);
    bottom: 65%;
  }
}

@keyframes closing {
  0% {
    transform: rotate(45deg);
    top: 35%;
  }
  100% {
    transform: rotate(0deg);
    top: 0;
  }
}

@keyframes closingLeft {
  0% {
    transform: rotate(-45deg);
    bottom: 65%;
  }
  100% {
    transform: rotate(0);
    bottom: 0;
  }
}

main {
  position: absolute;
  width: 100vw;
  height: 88vh;
  top: 12vh;
  padding-top: 2vh;
}

.content {
  margin: 13vh auto 0;
  height: 89vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
  max-width: 1024px;
}

::-webkit-scrollbar {
  width: 0;
}

/*=============================================================================
  Home
=============================================================================*/

.bottom-row {
  position: relative;
}

.profile-image {
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
  fill: #222;
  right:-20%;
}

.title-font {
  font-size: 2.5rem;
}

.intro-text {
  padding-right: 0;
  font: normal 1.2rem/1.5rem 'Roboto Mono', monospace;
}

.quick-links {
  margin-top: 20px;
  width:50%
}

.quick-links a {
  height: 50px;
  border: none;
  margin-bottom: 40px;
  background-color: #222;
  font: normal 1rem / 50px 'Roboto Mono', monospace;
  color: white;
  text-decoration: none;
  padding: 0 10px;
  display: inline-block;
  text-align:center;
  border-radius:2px;
}



/*=============================================================================
  Gallery
=============================================================================*/

.thumbnail {
  height: 0;
  width: 30%;
  padding-bottom: 30%;
  background-color: #222;
  position: relative;
  margin-bottom: 30px;
}

.thumbnail:before {
  content: '';
  width: 20%;
  height: 102%;
  position: absolute;
  left: 40%;
  top: -1%;
  background-color: white;
  z-index: 1
}

.thumbnail img {
  width: 80%;
  position: relative;
  margin: 10%;
  z-index: 1;
}

.thumbnail img:hover {
  cursor: pointer;
}

.portrait {
  width: 15vw;
}

.landscape {
  height: 100%;
}

.gallery {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
}

.modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  background-color: #222;
  z-index: 1000;
  color: white;
}

.modal button {
  position: absolute;
  height: 10vw;
  width: 10vw;
  top: 5vw;
  right: 5vw;
  font: bold 2.4em/8vw 'Roboto Mono', monospace;
  background-color: transparent;
  border: none;
  color: white;
}

.modal button:before, .modal button:after {
  content: '';
  position: absolute;
  height: 10vw;
  width: 2vw;
  border: 2vw solid white;
  border-right: none;
  left: -2vw;
  top: -2vw;
  z-index: 1000
}

.modal button:after {
  left: auto;
  right: -2vw;
  border: 2vw solid white;
  border-left: none;
}

.modal img {
  max-width:calc(100% - 40px);
  max-height:500px;
  outline: 1px solid white;
}

.modal-content {
  margin-top: 20vw;
  overflow: scroll;
  height: calc(100vh - 20vw);
  padding-bottom: 5vw;
}

.modal h2, .modal h3, .modal p {
  color: white;
}

.svg {
  width: 33vw;
}

.specs {
  display: flex;
  position: relative;
  width: 100%;
}

.specs div {
  width: 66vw;
}

.specs p, label {
  font: normal 3vw/1.6em 'Roboto Mono', monospace;
}

/*=============================================================================
  Contact
=============================================================================*/

form {
  padding: 4vw
}

.input-field {
  position: relative;
  height: 12vw;
  margin-bottom: 10vw;
}

.input-field:nth-of-type(3) {
  height: 20vw;
}

label {
  position: absolute;
  font: bold 4vw/1.6em 'Roboto Mono', monospace;
  display: block;
  background-color: white;
  z-index: 1;
  top: -4vw;
  left: 5vw;
  padding: 1vw;
}

input, textarea {
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 12vw;
  border: 1vw solid #222;
  font: normal 5vw/1.6em 'Roboto Mono', monospace;
  padding: 2vw 1vw 0;
  border-radius: 2px
}

textarea {
  height: 20vw;
  resize: none;
}

input:focus, textarea:focus {
  outline: none;
}

form button {
  padding: 3vw;
  background-color: transparent;
  border: 1vw solid #222;
  font: bold 5vw/1.6em 'Roboto Mono', monospace;
  display: inline-block;
  min-width:100px;
  border-radius: 2px;
}

button.submitting {
  background-color: #222;
  color: white;
  width: 10vw;
  animation: shrink .2s linear;
}

button.submitFailure, button.submitSuccess {
  width: 100%;
  color: white;
  background-color: #222;
  animation: grow .2s linear;
}

@keyframes grow {
  0% {
    width: 10vw;
  }
  100% {
    width: 100%;
  }
}

@keyframes shrink {
  0% {
    width: 18vw;
  }
  100% {
    width: 10vw;
  }
}

/*=============================================================================
  Loading
=============================================================================*/

.loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  text-align: center;
  font-size: 3em;
  padding-top: 50vh;
}

main {
  max-width: 1024px;
  margin: 0 auto;
}

.blur {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  filter: blur(.25em);
}

/*=============================================================================
  Development
=============================================================================*/

.details-button {
  border: none;
  background-color:transparent;
  text-decoration: underline;
  font: bold 1.2rem/1.5rem 'Roboto Mono', monospace; 
  margin-top:20px;
}

.hidden-details {
  display: none;
}

.project-item {
  margin-bottom:40px;
}

.resource-link {
  margin-right: 20px;
}
/*===========================================================================
  Design
===========================================================================*/
.design-item {
  margin-bottom:40px;
}

.slideshow {
  padding-bottom:10px;
  width: 100%;
  position:relative;
  min-height:200px;
  margin-bottom:30px;

}
.slideshow section {
  display:flex;
  justify-content:space-between;
  width:25%;
  right:38.5%;
  position: absolute;
  bottom:-10px;
}
.circles {
  width:10px;
  height:10px;
  border:1px solid #222;
}

.filled {
  background-color:#222;
}
 
.slideshow button {
  height:50px;
  width:20px;
  border:none;
  background-color:#222;
  color:white;
  position: absolute;
  top: calc(50% - 25px);
  left:0;
}

.slideshow button:nth-of-type(2) {
  left: auto;
  right:0;
}

.slideshow img {
  display:block;
  margin:0 auto 10px;
  max-height:400px;
  max-width:100%
}
/*===========================================================================
  Medium Screens
===========================================================================*/

@media screen and (min-width: 520px) {
  /*===========================================================================
    General
  ===========================================================================*/
  /*===========================================================================
    Home
  ===========================================================================*/
  .profile-image {
    position: static;
    margin-right: -25%;
    width: 100%;
    height: 40vh;
  }
  .quick-links {
    margin-top: 40px;
    width: 50%;
  }
  .intro-text {
    font-size: 1.5rem;
    line-height: 150%
  }
  .quick-links a {
    width:100%;
    font-size: 1.2rem
  }
  .bottom-row {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  /*===========================================================================
    Nav
  ===========================================================================*/
  .menu a {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  /*===========================================================================
    Contact
  ===========================================================================*/
  form button {
    width: auto;
  }
}

@media screen and (min-width:650px) {
  /*===========================================================================
    Home
  ===========================================================================*/
  .profile-image {
    margin-right: 0;
    width: 100%;
    height: 50vh;
  }
}

@media screen and (min-width:800px) {
  /*===========================================================================
    General
  ===========================================================================*/
  .content {
    /* padding-top:13vh; */
    height: 87vh;
    padding-bottom: 50px;
  }
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  /*===========================================================================
    Nav
  ===========================================================================*/
  .menu {
    width: 33vw;
  }
  .menu li {
    padding: 20px;
  }
  /*===========================================================================
    Home
  ===========================================================================*/
  .bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .profile-image {
    width: 60%;
    position: static;
  }
  .quick-links {
    margin-top: 0;
    width: 40%;
  }
  .title-font {
    font-size: 3.5rem;
  }
  .intro-text {
    font-size: 150%;
    line-height: 175%;
  }
  .quick-links a {
    font-size: 150%;
    width: 100%;
    padding: 0;
  }

  .quick-links a:nth-of-type(1) {
    margin-top: 0;
  }
  /*===========================================================================
    Development
  ===========================================================================*/
  .visible-details, .hidden-details {
    display: block;
  }
  .details-button {
    display: none;
  }
  .specs p, label {
    font: normal 1.5rem/3rem 'Roboto Mono', monospace;
  }
  .specs .svg {
    width: 30%;
  }
  .specs {
    margin-bottom: 20px;
  }
  .details section {
    margin-bottom: 20px
  }
  /*===========================================================================
    Contact
  ===========================================================================*/
  form {
    padding: 0;
    width: 50%;
  }
  .input-field {
    height: 50px;
    margin-bottom: 40px;
  }
  .input-field input {
    height: 50px;
  }
  .input-field textarea, .input-field input, button[type=submit] {
    border: 5px solid #222;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0 10px;
  }
  .input-field textarea {
    padding-top: 20px
  }
  .input-field label {
    font-size: 1.5rem;
    line-height: 0rem;
    /* outline:1px solid blue; */
    top: -0.75rem;
  }
  button[type="submit"] {
    font-size: 1.5rem;
    line-height: 1.5rem;
    height: 50px;
  }
  .error {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 13vh;
    z-index: 10000;
    height: 50px;
    border-bottom: 1px solid #703030;
    color: #703030;
    font-weight: bold;
    background-color: #a36363;
    padding: 0 20px;
    line-height: 50px;
  }
  /*===========================================================================
    Contact
  ===========================================================================*/
  .modal button {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .modal button:before, .modal button:after {
    height: 50px;
    width: 10px;
    border: 10px solid white;
    border-right: none;
    left: -10px;
    top: -10px;
    z-index: 1000
  }
  .modal button:after {
    left: auto;
    right: -10px;
    border: 10px solid white;
    border-left: none;
  }
  .modal-content {
    margin-top: 120px;
    height: calc(100vh - 120px);
    max-width: 1024px;
    margin: 120px auto 0;
  }
}

@media screen and (min-width: 1000px){
  nav {
    background-color: transparent;
  }
  .content {
    margin-top:0;
    min-height:100vh;
    padding-top:100px;
  }
}